.hero {
    position: fixed;  /* changed from static to fixed */
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;  /* make the hero component below the content */
    font-family: Montserrat;
}
  
.background-video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
  
.transparency-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(40, 40, 40, 0.2);
}

.hero-content {
    position: relative;
    text-align: center;
    opacity: 1;
    
    transition: opacity 1s ease-in-out;
    font-family: Montserrat;
}


.card h1{
    font-weight: 400;
}

.card h2{
    font-weight: 100;
}
  
.center_Logo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;  /* keep the logo and text above the background video */
}
  
.img_logo{
    max-width: 800px;
    width: 100%;
}
/* rest of the code remains the same... */


.fade-out {
    animation: fade-out 1s ease-in-out forwards;
}
  
@keyframes fade-out {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}
  
h1{
    font-size: 2rem;
    color: whitesmoke;
}

.card {
    text-transform: uppercase;
    padding: 2em;
    border-radius: 5px;
    color: whitesmoke;
}

/* CSS Media Queries for Responsiveness */

@media (max-width: 1200px) {
    h1 {
        font-size: 1.75rem;
    }
    
    .img_logo {
        max-width: 600px;
    }
}

@media (max-width: 900px) {
    h1 {
        font-size: 2rem;
    }
    h2{
        font-size: 1rem;
    }
    .center_Logo{
        justify-content: center;
        align-items: center;
    }
    
    .img_logo {
        max-width: 500px;
    }
}

@media (max-width: 600px) {
    h1 {
        font-size: 1.2rem;
    }
    h2{
        font-size: 1rem;
    }
    
    .img_logo {
        max-width: 400px;
        align-items: center;
        justify-content: center;
    }
    .center_Logo{
        justify-content: center;
        align-items: center;
    }

    .card {
        padding: 1em;
    }
}
