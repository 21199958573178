.whatWeDo {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #3f7cad;
    box-shadow: 0px 0px 20px 10px #3f7cad;
}
  
.section-cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 20px;
}
  
.section-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 30%;
    text-align: center;
    max-width: 20%;
    color: whitesmoke;
    
    box-sizing: border-box;
    margin-bottom: 20px;
    transition: all 0.5s ease-in-out;
}

.section-card p{
    font-size: 1.5rem;
}

.section-card h2{
    font-size: 2rem;
}

.section-card:hover{
    box-shadow: 0px 0px 20px 10px black;
    transition: all 0.5s ease-in-out;
    border-radius: 1rem;
    .icon_wwd{
        box-shadow: 0px 0px 20px 10px whitesmoke;
        transition: all 0.5s ease-in-out;
        color: red;
    }
}
.heading_wrapper{
    width: 80%;
    margin-top: 2rem;
    padding: 1rem;
    margin-bottom: 2rem;
}

.heading_section{
    color: whitesmoke;
    font-size: 2.5rem;
}

.subheading_section{
    color: whitesmoke;
    margin-top: 2rem;
    font-size: 1.5rem;
}
  
.icon_wwd {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
    color: #3f7cad;
    background: white;
    border-radius: 50%;
    padding: 10px;
    width: 60px;  /* or the size you want */
    height: 60px;  /* or the size you want */
    transition: all 0.5s ease-in-out;
}
  
.list-items {
    display: flex;
    align-self: flex-start;
    color: white;
}
  
.list-items ul {
    text-align: left;
    justify-content: left;
    font-size: 1.5rem;
}
  
.list-items li {
    margin-bottom: 10px;
    display: flex;
    align-items: left;
    width: 100%;
}
  
.list-items .list-icon {
    margin-right: 10px;
}


@media (max-width: 600px) {
    .section-cards {
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        width: fit-content;
        align-items: center;
    }

    .section-card {
        max-width: 90%;
    }
}

@media (max-width: 900px) {
    .section-cards {
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        width: fit-content;
        align-items: center;
    }

    .section-card {
        max-width: 90%;
    }
}