.about-us-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #3f7cad;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px 10px #3f7cad;
}

.about-us-content {
  flex: 1 1 30%;
  max-width: 30%;
  word-spacing: 3px;
  margin: 10px;
}

.about-us-content h1 {
  color: whitesmoke;
  font-size: 3rem;
  text-align: left;
  font-family: Montserrat;
  font-weight: 400;
}

.about-us-content p {
  font-size: 1.4rem;
  text-align: left;
  color: whitesmoke;
  font-family: Montserrat;
  font-weight: lighter;
}

.about-us-image {
  flex: 1 1 40%;
  max-width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.about-us-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  box-shadow: 2px 2px 20px 0px whitesmoke;
  border-radius: 1rem;
}

/* Responsive layout */
@media (max-width: 768px) {
  .about-us-main {
    flex-direction: column;
    align-items: center;
  }

  .about-us-content {
    max-width: 100%;
  }

  .about-us-image {
    max-width: 100%;
  }
}
