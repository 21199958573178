
#container {
  z-index: 111;
}

.navT {
  -webkit-transition: all 0.25s;
  z-index: 111;
  cursor: pointer;
  width: 100%;
  background: #212121;
  height: 85px;
  left: 0;
  top: 0;
  position: fixed;
}
.navT .icon {
  -webkit-transition: all 0.25s;
  position: absolute;
  width: 30px;
  height: 1px;
  left: 15px;
  top: 40px;
  background-color: white;
}
.navT .icon:before, .navT .icon:after {
  -webkit-transition: all 0.25s;
  width: 30px;
  height: 1px;
  left: 0;
  background: white;
  position: absolute;
  content: "";
}
.navT .icon:before {
  top: -10px;
}
.navT .icon:after {
  top: 10px;
}
.navT:hover {
  background: black;
}
.navT:hover .icon:before {
  top: -12px;
}
.navT:hover .icon:after {
  top: 12px;
}
.navT.active .icon {
  -webkit-transform: rotate(180deg);
  background-color: transparent;
  left: 10px;
}
.navT.active .icon:before, .navT.active .icon:after {
  top: 0;
}
.navT.active .icon:before {
  -webkit-transform: rotate(45deg);
}
.navT.active .icon:after {
  -webkit-transform: rotate(-45deg);
}

#menu {
  z-index: 111;
  -webkit-transition: all 0.5s;
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 85px;
  font-size: 18px;
  font-family: Montserrat;
  font-weight: 100;
  text-transform: uppercase;
  width: 100%;
  height: 0;
  border-bottom: 1px solid transparent;
  overflow: hidden;
}
#menu ul {
  list-style: none;
  padding: 0;
  letter-spacing: 0.4em;
}
#menu li:first-child {
  margin-top: 0;
}
#menu li {
  -webkit-transition: all 0.2s;
  margin-top: 20px;
  padding: 5px 0;
  color: white;
}
#menu a {
  padding: 8px;
  -webkit-transition: all 0.25s;
  cursor: pointer;
}
#menu a:hover {
  letter-spacing: 0.5em;
  text-decoration: underline;
}
#menu.open {
  height: 270px;
  width: 100%;
  background: #1e3c55;
  border-bottom: 1px solid black;
}

.image_logo{
    max-width: 300px;
    margin-top: 0.5rem;
}
