.parallax {
    /* Use backdrop.png as the background */
    background-image: url('./backdrop.png');
  
    /* Set the background image to be fixed, creating the parallax effect */
    background-attachment: fixed;
  
    /* Position and size the background image */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  
    /* Set the height of the section */
    height: 60vh;
  
    /* Use a flex layout to center the text */
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .parallax-content {
    /* Set the text color to white */
    color: white;
  
    /* Add some padding */
   
  
    /* Add text shadow for readability */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.3);
    
    width: 100%;
    height: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;
    
  }
  
  .heading {
    justify-content: center;
    font-size: 3em;
    margin-bottom: 2rem;
    text-align: left;
    align-items: center;
    padding: 1rem;
  }
  
  .description {
    justify-content: center;
    max-width: 40%;
    font-size: 1.5em;
    margin-top: 5rem;
    text-align: left;
    align-items: center;
    padding: 1rem;
  }
  

  @media (max-width: 1200px) {
    .heading {
        font-size: 2em; /* Adjust font size */
        margin-bottom: 1rem; /* Adjust margin */
    }

    .description {
        max-width: 80%; /* Adjust max width */
        font-size: 1.2em; /* Adjust font size */
        margin-top: 2rem; /* Adjust margin */
    }

    .parallax {
      height: 40vh;
     }

}

/* Responsive styling for screens smaller than 600px */
@media (max-width: 600px) {
    .heading {
        font-size: 1.5em; /* Adjust font size */
        margin-bottom: 0.5rem; /* Adjust margin */
    }

    .description {
        max-width: 100%; /* Adjust max width */
        font-size: 1em; /* Adjust font size */
        margin-top: 1rem; /* Adjust margin */
    }

    .parallax {
      height: 40vh;
     }

}

@media (max-height: 600px){
  .parallax {
   height: 100vh;
  }
}
  
